import React from "react";
import DsTooltip from "components/common/DsTooltip";

const Member = ({ member }) => {
    return (
        <DsTooltip
            id={`member-${member.id}`}
            title={member.first + " " + member.last}
        >
            <div id={`member-${member.id}`} className="member">
                <img src={member.pics} alt="member" />
            </div>
        </DsTooltip>
    );
};

export default Member;
