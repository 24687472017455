import React from "react";
import { connect } from "react-redux";
import { loginBack } from "helper/constant";
import ProfileInfoComponent from "components/profile/ProfileInfoComponent";


const UserProfile = () => {
   
    const Back = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    };

    const activeColor = {
        color: '#035184'
    };

    return (
        <div className="Profile-component">
            <div style={Back} className="background" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-lg-4 col-xl-3 profile-left-shade">
                        <ProfileInfoComponent activeColor={activeColor} />
                    </div>

                    <div className="col-md-12 col-lg-8 col-xl-9 profile-right-shade">
                        <div className="profile-header-panel pa-10">
                            <div className="headline">DS Digital</div>
                            <div>Administrador</div>
                        </div>

                        

                        
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger
    };
};

export default connect(
    mapStateToProps,
    null
)(UserProfile);
