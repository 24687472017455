export const sidebarData = [
  {
    type: "heading",
    name: "Solicitações"
  },
  {
    name: "sidebar.maps",
    routepath: "/order-service",
    iconClass: "fas fa-map-marker-alt"
  },
  {
    type: "heading",
    name: "Projetos"
  },
  {
    name: "Rio do Antônio",
    iconClass: "fas fa-expand",
    child: [
      {
        listname: "25-04-2024",
        iconClass: "fas fa-plus",
        child: [
          {
            listname: "Gêmeos Digitais",
            routepath: "/gemeo-digital",
            shortname: "-"
          }
        ]
      }
    ]
  },
  {
    type: "heading",
    name: "sidebar.user"
  },
  {
    name: "sidebar.userprofile",
    routepath: "/perfil",
    iconClass: "far fa-user"
  },
  // {
  //   type: "heading",
  //   name: "sidebar.admin"
  // },
  // {
  //   name: "Gerenciar Projetos",
  //   routepath: "/workflow",
  //   iconClass: "fas fa-clipboard-list"
  // }
];
