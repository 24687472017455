import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const DsTooltip = ({ id, title, children, placement = "bottom" }) => {
  return (
    <span>
      {children}
      <UncontrolledTooltip
        className="ds-tooltip"
        arrowClassName="ds-tooltip-arrow"
        innerClassName="ds-tooltip-inner"
        placement={placement}
        target={id}
      >
        {title}
      </UncontrolledTooltip >
    </span>
  );
}

export default DsTooltip