import React, { useState } from 'react';
import path from 'path';

const importAll = (r) => r.keys().map(r);
const imagensArray = importAll(require.context('../../assets/images/filemanager/centro', false, /\.(png|JPE?G|svg)$/));

const Cartografia = () => {
  const [imagemExpandida, setImagemExpandida] = useState(null);

  const handleClick = (imagem) => {
    setImagemExpandida(imagemExpandida === imagem ? null : imagem);
  };

  const handleDownloadAll = () => {
    // Substitua o link abaixo pelo seu link do Google Drive ou outra fonte de download.
    const downloadLink = 'https://drive.google.com/u/0/uc?id=11PVoBfjgjmIon2zMFZ7gSAsx5w5EKG6Z&export=download';

    // Abre o link em uma nova aba ao clicar no botão "Baixar Todas as Imagens".
    window.open(downloadLink, '_blank');
  };

  return (
    <div>
      <h2 className="mb-3">Centro de Convenções - 27/09/2023</h2>
      <p className="text-info mb-3">Clique no nome da imagem para exibi-la em tamanho maior.</p>
      <button className="btn btn-primary mb-3" onClick={handleDownloadAll}>
        Baixar Todas as Imagens
      </button>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Nome da Imagem</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {imagensArray.map((imagem, index) => (
            <tr key={index}>
              <td style={{ cursor: 'pointer' }} onClick={() => handleClick(imagem)}>
                {imagemExpandida === imagem ? (
                  <img src={imagem} alt={`Imagem ${index}`} className="img-fluid" style={{ maxHeight: '600px' }} />
                ) : (
                  <span>{path.basename(imagem)}</span>
                )}
              </td>
              <td>
                <a href={imagem} download style={{ color: 'green' }}>
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Cartografia;