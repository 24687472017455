import React, { Component } from "react";
import "./potree.css";

export default class PotreePointcloud extends Component {
    componentDidMount() {
        const viewer = new window.Potree.Viewer(document.getElementById("potree_render_area"));

        viewer.setEDLEnabled(false);
        viewer.setFOV(60);
        viewer.setPointBudget(1_000_000);
        viewer.loadSettingsFromURL();
        viewer.setBackground("skybox");
        viewer.loadGUI(() => {
            viewer.setLanguage('br');
            window.$("#menu_tools").next().show();
            window.$("#menu_clipping").next().show();
            viewer.toggleSidebar();
        });
        
        window.viewer = viewer;

        const metadataUrl = "./potree/pointclouds/falesia/metadata.json";

        fetch(metadataUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(metadata => {
                const name = metadata.name;

                viewer.setDescription(name);

                window.Potree.loadPointCloud(metadataUrl, name, (e) => {
                    let scene = viewer.scene;
                    let pointcloud = e.pointcloud;

                    console.log(e);

                    let material = pointcloud.material;
                    material.size = 1;
                    material.pointSizeType = window.Potree.PointSizeType.ADAPTIVE;
                    material.shape = window.Potree.PointShape.SQUARE;

                    scene.addPointCloud(pointcloud);
                    viewer.fitToScreen();
                });
            })
            .catch(error => {
                console.error("Failed to load pointcloud metadata:", error);
            });
    }

    render() {
        return (
            <div style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 65 }}>
                <div id="potree_render_area"></div>
                <div id="potree_sidebar_container"></div>
            </div>
        );
    }
}