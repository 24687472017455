import React from "react";
import { ProfileLockScreen } from "./../../helper/constant";

const ProfileInfoComponent = ({ activeColor }) => {
    return (
        <div>
            <div className="text-center profile-image">
                <img src={ProfileLockScreen} alt="Name" />
            </div>
            <div className="pa-10">
                <div style={activeColor} className="headline">
                    Configurações
                </div>
                <div style={activeColor} className="headline pt-16">
                    Histórico
                </div>
                <div style={activeColor} className="headline pt-16">
                    Pagamento
                </div>
                <div style={activeColor} className="headline pt-16">
                    Encerrar Conta
                </div>
            </div>
        </div>
    );
};

export default ProfileInfoComponent;
